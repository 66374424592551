import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../layouts/layout"
import SEO from "../components/SEO/index.js"
const browser = typeof window !== "undefined" && window;

class NotFoundPage extends React.Component {
  render() {
    //rgb(0, 106, 193);
    //rgba(144, 205, 249, 0.1);
    return (
      browser && (
        <Layout location={this.props.location}>
          <h1>404 - Not Found</h1>
        </Layout>
      )
    )
  }
}

export default NotFoundPage
